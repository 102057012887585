import logo from './logo6new.png';
import React, { Component } from 'react';

/*
import Header from './components/Header';
import SideBar from './components/SideBar';
import Content from './components/Content';
*/
import './App.css';

/*
      <div>

        <Header />
        <SideBar />
        <Content />

      </div>    


      <a href="https://pancakeswap.finance/swap#/swap?outputCurrency=0x7F44a04175983FbeEbE5Bb0083C36F41e88c244B" target="_blank">
      <code><b> Blochain</b></code>
        </a>
        <a href="https://game-zone.6stem.com/" target="_blank">
      <code><b> Game-zone</b></code>
        </a>
        <a href="https://web-app.6stem.com/" target="_blank">
      <code><b> Web-App</b></code>
        </a>                
*/
function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          <code><b>  6stem.com :  Numeric System Automation</b></code>
        </p>
        <img src={logo} className="App-logo" alt="logo" />
      </header>
  
    </div>
  );
}

export default App;